<template>
	<div>
		<div class="photoDetailBox">
			<div class="infoBox">
				<div class="infoLeft">
					
					<el-image v-if="detailInfo&&detailInfo.pic_str" style="width: 100%;" :src="detailInfo.pic_str" :preview-src-list="[detailInfo.pic_str]"></el-image>
					<!-- <img :src="detailInfo.pic_str" @click="handlePictureCardPreview(detailInfo.pic_str)" alt="" /> -->
				</div>
				<div class="infoRight">
					<div>
						<p class="f0404 f20 f_b">{{ detailInfo.title }}</p>
						<div class="flex-sb mt-24">
							<div class="down flex-sb" @click="showDropFn()">
								<span class="down-text">
									图片下载
								</span>
								<span class="down-icon">
									<i class="el-icon-arrow-down icon-tran" :class="{'icon-trans': showDrop}"></i>
								</span>
								<div class="dropdown" v-show="showDrop">
									<div class="dropdown-item flex-cc" @click="download(2)">
										<img src="../../assets/image/nVIP.png" alt="">
										<span>普通图</span>
										<span>{{detailInfo.thumbnail_width}}*{{detailInfo.thumbnail_height}}</span>
									</div>
									<div class="dropdown-item flex-cc" @click="download(1)">
										<img src="../../assets/image/VIP.png" alt="">
										<span>高清图</span>
										<span>{{detailInfo.width}}*{{detailInfo.height}}</span>
									</div>
								</div>
							</div>
							<div class="collect flex-cc" @click="collect(detailInfo)">
								<img v-if="isCollect" class="collect-icon" src="@/assets/image/sc.png" alt="">
								<img v-else class="collect-icon" src="@/assets/image/scn.png" alt="">
								<span>收藏</span>
							</div>
						</div>
					</div>
					<div v-show="albumObjList&&albumObjList.data&&albumObjList.data.length">
						<div class="flex-sb album-title">
							<div class="flex-ct ">
								<img class="album-icon" src="@/assets/image/tj.png" alt="">
								<span class="txt-over" style="max-width: 2.6rem;">
									{{detailInfo.album_name || '更多图片'}}
								</span>
							</div>
							<span class="album-num">
								{{albumObjList.total}}张
							</span>
						</div>
						<div class="album-list">
							<PhotoList index="albumList" ref="albumList" :loading="albumLoading" :colNum="activeTab"
								:listStatus="albumListStatus" :photoListObj="albumObjList" :albumList="true"
								refName="refAlbumList" @nextPage="albumNextPage">
							</PhotoList>
						</div>
					</div>
					<div @click="jump(bannerList.url)">
						<img class="getListParams" :src="bannerList.src" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="recommendBox ">
			<p>相关推荐</p>
			<PhotoList index="recommendList" ref="recommendList" :loading="loading" :colNum="activeTab"
				:listStatus="listStatus" :photoListObj="recommendList" @nextPage="nextPage">
			</PhotoList>
		</div>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import PhotoList from "./componts/PhotoList.vue";
	import {
		photoGetDetail,
		getCollectView,
		getPhotoIsCollected,
		getPhotoRelatedPic,
		getPhotoPicAlbumRelated,
		checkDownTimes,
		getBanner
	} from "../../Api";
	import {
		getToken
	} from "../../utils/auth";
	export default {
		name: "PhotoDetail",
		components: {
			PhotoList,
		},
		data() {
			return {
				bannerList: {},
				dialogImageUrl: '',
				dialogVisible: false,
				showDrop: false,
				activeTab: 1,
				bannerHeight: 0,
				detailId: "",
				detailInfo: "",
				isCollect: false,
				collectPop: {},
				loading: true,
				getListParams: {
					id: this.$route.query.detailId,
					fid: 2381,
					page: 0,
					per_page: 30,
				},
				listStatus: {
					isData: true,
					isNull: false,
				},
				recommendList: {
					total: 0,
					per_page: 20,
					current_page: 0,
					data: [],
				},
				albumLoading: true,
				albumObjList: {
					total: 0,
					per_page: 20,
					current_page: 0,
					data: [],
				},
				getAlbumListParams: {
					id: this.$route.query.detailId,
					fid: 2381,
					page: 0,
					per_page: 30,
				},
				albumListStatus: {
					isData: true,
					isNull: false,
				},
			};
		},
		created() {
			this.detailId = this.$route.query.detailId;
			photoGetDetail({
				id: this.detailId,
				token: getToken()
			}).then((res) => {
				this.detailInfo = res.data;
				document.title = res.data.title + " - 图库 - 知设网";
				this.getListParams.page = 1;
				this.getAlbumListParams.page = 1;
			});
			//获取图库是否收藏
			getPhotoIsCollected({
				pid: this.detailId,
				token: getToken(),
				fid: 2381
			}).then(
				(res) => {
					this.isCollect = res.data.message == "collected" ? true : false;
				}
			);
			// 获取广告banner
			getBanner({
				position: 2564,
				cate: 2565,
			}).then((res) => {
				this.bannerList = res.data&&res.data[0];
			});
		},
		methods: {
			// 加载下一页
			nextPage() {
				this.getListParams.page += 1;
			},
			// 专辑加载下一页
			albumNextPage() {
				this.getAlbumListParams.page += 1;
			},
			handlePictureCardPreview(url) {
				this.dialogImageUrl = url;
				this.dialogVisible = true;
			},
			collect(info) {
				//判断是否收藏过
				if (this.isCollect) {
					return;
				}
				getCollectView({
					token: getToken(),
					fid: 2381
				}).then((res) => {
					if (res.data.error == "-1") {
						this.$login();
					}
					this.collectList = res.data;
					info.img = info.pic;
					this.collectPop = this.$collectPop({
						collectList: this.collectList,
						detailInfo: info,
						pid: this.detailId,
						token: getToken(),
						fid: 2381,
					});
					this.collectPop.show()
				});
			},
			showDropFn() {
				if (!getToken()) {
					this.$login();
					return;
				}
				this.showDrop = !this.showDrop
			},
			download(type) {
				if (!getToken()) {
					this.$login();
					return;
				}
				if (type == 1 && this.userInfo && this.userInfo.is_vip && !(this.userInfo.is_vip.library.is_vip == 1)) {
					const {
						href
					} = this.$router.resolve({
						path: "/vip/material"
					});
					window.open(href, '_blank')
					return
				}
				checkDownTimes({
					token: getToken(),
					id: this.detailId
				}).then((res) => {
					if (res.data && res.data.error == 0) {
						const downloadUrl =
							this.$ROOT_DIR +
							"/web/Photo/down?id=" +
							this.detailId +
							"&type=" + type + "&token=" +
							getToken();
						let link = document.createElement("a");
						link.style.display = "none";
						link.href = downloadUrl;
						document.body.appendChild(link);
						link.click();
					} else if (res.data && res.data.error == -1) {
						this.$tooltipPop().show(1, res.data.msg)
					} else if (res.data && res.data.error == -2) {
						this.$tooltipPop().show(2, res.data.msg)
					} else {
						this.$tooltipPop().show(0, res.data.msg)
					}
				}).catch((err) => {
					console.log(err);
				});
			},
			// 获取数据
			getAlbumList() {
				this.albumLoading = true;
				this.albumListStatus.isNull = false;
				this.albumListStatus.isData = true;
				if (this.getAlbumListParams.page == 1) {
					this.$refs.albumList && this.$refs.albumList.clear();
				}
				getPhotoPicAlbumRelated(this.getAlbumListParams)
					.then((res) => {
						this.albumLoading = false;
						let data = res.data;
						this.albumObjList = data
						if (data.total == 0) {
							this.$refs.albumList.clear();
							this.albumListStatus.isNull = true;
						} else if (data.current_page == data.last_page) {
							this.albumListStatus.isData = false;
						}
					})
					.catch((error) => {
						console.log("错误", error);
					});
			},
			
			jump(url) {
				if(url) {
					window.open(url, '_blank')
				}
			},
			// 获取数据
			getList() {
				this.loading = true;
				this.listStatus.isNull = false;
				this.listStatus.isData = true;
				if (this.getListParams.page == 1) {
					this.$refs.recommendList && this.$refs.recommendList.clear();
				}
				getPhotoRelatedPic(this.getListParams)
					.then((res) => {
						this.loading = false;
						let data = res.data;
						this.recommendList = data

						if (data.total == 0) {
							this.$refs.recommendList.clear();
							this.listStatus.isNull = true;
						} else if (data.current_page == data.last_page) {
							this.listStatus.isData = false;
						}
					})
					.catch((error) => {
						console.log("错误", error);
					});
			},
		},
		computed: {
			...mapGetters(['userInfo']),
		},
		watch: {
			$route() {
				this.$router.go(0);
			},
			"collectPop.isCollect"(val) {
				this.isCollect = val
			},
			getListParams: {
				handler: function() {
					this.getList()
				},
				deep: true,
			},
			getAlbumListParams: {
				handler: function() {
					this.getAlbumList()
				},
				deep: true,
			},
		},
	};
</script>

<style scoped>
	.photoDetailBox {
		width: 100%;
		min-width: 1400px;
		background: #f7f6fb;
		padding: 0.4rem 0;
	}

	.infoBox {
		width: 9.8rem;
		margin: 0 auto;
		background: #ffffff;
		border-radius: 0.16rem;
		overflow: hidden;
		display: flex;
	}

	.infoLeft {
		flex: 1;
		/* max-height: 9.75rem; */
		overflow: hidden;
		display: flex;
		align-items: center;
		background-color: #F2F2F2;
		box-shadow: 0px 0px 3px 0px rgba(206, 206, 206, 0.48);
	}

	.infoLeft img {
		width: 100%;
		vertical-align: middle;
	}

	.infoRight {
		width: 3.8rem;
		padding: 0.25rem;
	}

	.mt-24 {
		margin-top: 0.24rem;
	}

	.down {
		position: relative;
		width: 1.5rem;
		height: 0.44rem;
		background: #F46600;
		border-radius: 0.04rem;
		font-size: 0.16rem;
		line-height: 0.44rem;
		text-align: center;
		color: #ffffff;
		cursor: pointer;
	}

	.down-text {
		width: 1.12rem;
	}

	.down-icon {
		position: relative;
		width: 0.38rem;
	}

	.down-icon::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0.1rem;
		width: 0px;
		height: 0.24rem;
		border-left: 1px solid #DE630B;
	}

	.icon-tran {
		transform: rotate(0);
		transition: .3s;
	}

	.icon-trans {
		transform: rotate(180deg);
	}

	.dropdown {
		position: absolute;
		left: 0;
		top: 0.5rem;
		z-index: 8;
		width: 1.5rem;
		background-color: #ffffff;
		border-radius: 0.04rem;
		box-shadow: 0 0.05rem 0.09rem 0.01rem rgba(0, 0, 0, 0.2);
	}

	.dropdown-item {
		overflow: hidden;
		display: flex;
		font-size: 0.12rem;
		color: #999999;
		white-space: nowrap;
		cursor: pointer;
	}

	.dropdown-item img {
		width: 0.2rem;
		height: 0.20rem;
	}

	.dropdown-item span {
		margin: 0 0.02rem;
	}

	.dropdown-item:nth-child(2) {
		color: rgba(244, 102, 0, 1);
		;
	}

	.collect {
		width: 1.5rem;
		height: 0.44rem;
		border: 1px solid #F46600;
		border-radius: 0.04rem;
		line-height: 0.44rem;
		text-align: center;
		cursor: pointer;
	}

	.collect span {
		margin: 0 0.05rem;
		font-size: 0.16rem;
		font-weight: 500;
		color: rgba(244, 102, 0, 1);
	}

	.collect-icon {
		font-size: 0;
		vertical-align: middle;
		width: 0.2rem;
		height: 0.2rem;
	}

	.album-title {
		margin: 0.32rem 0 0.16rem;
		font-size: 0.14rem;
		font-weight: bold;
		color: rgba(4, 4, 4, 1);
	}

	.album-icon {
		font-size: 0;
		vertical-align: middle;
		width: 0.16rem;
		height: 0.16rem;
		margin-right: 0.08rem;
	}

	.album-num {
		font-weight: 400;
		color: rgba(166, 166, 166, 1);
	}

	.album-list {
		/* height: 2.4rem; */
		margin-right: -0.1rem;
		border-radius: 0.04rem;
		overflow-y: overlay;
		overflow-x: hidden;
	}

	.getListParams {
		width: 3.32rem;
		border-radius: 0.04rem;
		margin: 0.32rem 0 0;
	}


	.album-list>>>.vue-waterfall {
		width: 100%;
		border-radius: 0.04rem;
		padding: 0 0.04rem 0 0;
		overflow-y: overlay;
	}

	.album-list>>>.vue-waterfall::-webkit-scrollbar {
		display: none;
		width: 4px;
	}

	.album-list:hover>>>.vue-waterfall::-webkit-scrollbar {
		display: block;
	}

	/*定义滚动条轨道 内阴影+圆角*/
	.album-list>>>.vue-waterfall::-webkit-scrollbar-track {
		border-radius: 2px;
		background-color: rgba(0, 0, 0, 0.1);
	}

	/*定义滑块 内阴影+圆角*/
	.album-list>>>.vue-waterfall::-webkit-scrollbar-thumb {
		border-radius: 2px;
		/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
		background-color: rgba(0, 0, 0, 0.2);
	}



	.el-carousel__item img {
		width: 100%;
	}

	::v-deep .el-carousel__container {
		max-height: 9.75rem;
		/*height: auto;*/
	}

	.recommendBox {
		width: 100%;
		overflow: hidden;
		min-width: 1400px;
		padding: 0.4rem 1.2rem 0.32rem;
		background-color: #ffffff;
		text-align: center;
	}

	.recommendBox>>>.vue-waterfall {
		overflow: hidden;
	}

	.recommendBox>p {
		font-size: 0.3rem;
		font-weight: bold;
	}

	.readList {
		margin-top: 0.15rem;
		margin-right: -0.1rem;
	}

	.readList:after {
		content: "";
		visibility: hidden;
		display: block;
		width: 0;
		height: 0;
		clear: both;
	}

	.readItem {
		width: 22.72%;
		height: 0.68rem;
		border-radius: 0.04rem;
		margin-right: 0.1rem;
		background: coral;
		float: left;
		cursor: pointer;
		margin-bottom: 0.1rem;
	}

	.readItem img {
		width: 100%;
		height: 0.68rem;
	}

	.bank {
		font-size: 0.3rem;
	}

	.Collected {
		color: #f46600;
	}
</style>
